import React from "react"

const CartFinishStatus = ({ success }) => {
  if (success === true) {
    return (
      <div className="shippStatus success">
        <h3>Zamówienie zostało zrealizowane poprawnie</h3>
        <p>
          Na twój adres e-mail został wysłany numer konta na który należy
          wpłacić pieniądze.
        </p>
        <p>Plakat zostanie wysłany od razu po zaksięgowaniu pieniędzy.</p>
        <p>
          Jeśli chcesz aby plakat został wysłany szybciej, skontatuj się ze mną
          - więcej informacji na stronie kontakt
        </p>
      </div>
    )
  } else if (success === false) {
    return (
      <div className="shippStatus failed">
        <h3>Zamówienie NIE zostało zrealizowane poprawnie</h3>
        <p>Proszę spróbować jeszcze raz.</p>
        <p>
          Jeśli nic się nie zmieni proszę o kontakt telefoniczny, fb lub e-mail.
        </p>
      </div>
    )
  } else {
    return (
      <div className="shippStatus">
        <h3>Zamówienie w trakcie realizacji</h3>
      </div>
    )
  }
}

export default CartFinishStatus
