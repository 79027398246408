import React, { useState, useEffect } from "react"

const CartFinishSuma = ({ cart, shippMethod, currency }) => {
  const [USDtoPLNRate, setRate] = useState(4)

  const fetchUsdRates = async () => {
    return await fetch("https://api.nbp.pl/api/exchangerates/rates/a/usd/")
      .then(response => response.text())
      .then(str => JSON.parse(str))
      .then(data => setRate(data.rates[0].mid))
  }
  useEffect(() => {
    fetchUsdRates()
  }, [])

  // console.log(fetchUsdRates())

  const currencyToUSD = { PLN: 1, BTC: 19000.0, ETH: 1300, TRX: 0.06158 }

  let suma = 0
  cart.forEach(item => {
    suma += item.cena
  })
  suma += shippMethod
  if (currency != "PLN") {
    suma = suma / USDtoPLNRate / currencyToUSD[currency]
  }

  let trNum = 0
  return (
    <div className="cartFinalSuma">
      <h2>Podsumowanie:</h2>
      <table>
        <tbody>
          {cart.map(item => {
            let trKey = "row" + trNum
            let tdKey = "col" + trNum
            trNum++
            return (
              <tr key={trKey}>
                <td key={tdKey}>{item.name}</td>
                <td key={tdKey + 1}>{item.cena} PLN</td>
              </tr>
            )
          })}
          <tr>
            <td>Dostawa</td>
            <td>{shippMethod} PLN</td>
          </tr>
          <tr className="cartFinishSuma">
            <td colspan="2">
              <span className="cartFinishSumaSpan">
                <p>Suma </p>
                <p>
                  {suma} {currency}{" "}
                </p>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default CartFinishSuma
