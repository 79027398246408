import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import "../components/form-basic.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CartContext from "../context/cart-context"
import CartFinishSuma from "../components/cart/finish-suma"
import CartFinishStatus from "../components/cart/finish-status"

import ReactGA from "react-ga"

class Cartfinish extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      surname: "",
      address: "",
      city: "",
      postalCode: "",
      email: "",
      mobile: "",
      success: null,
      kosztDostawy: 20,
      shippMethod: "kurier",
      currency: "PLN",
      regulamin: false,
      checkBoxStyle: "regulaminCheckBox",
      USDtoPLNRate: 4,
    }
    this.kosztKurier = 20

    this.handleInputChange = this.handleInputChange.bind(this)

    this.selectShippMethod = this.selectShippMethod.bind(this)
    this.selectCurrency = this.selectCurrency.bind(this)
  }
  handleInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit(cart) {
    console.log(cart)
    if (this.state.regulamin === false) {
      alert("Proszę zaakceptować regulamin")
      this.setState({ checkBoxStyle: "regulaminCheckBox alert" })
    } else {
      console.log(
        JSON.stringify({
          name: this.state.name,
          surname: this.state.surname,
          address: this.state.address,
          city: this.state.city,
          postalCode: this.state.postalCode,
          email: this.state.email,
          mobile: this.state.mobile,
          currency: this.state.currency,
        })
      )

      const fetchUsdRates = async () => {
        return await fetch("https://api.nbp.pl/api/exchangerates/rates/a/usd/")
          .then(response => response.text())
          .then(str => JSON.parse(str))
          .then(data => this.setState({ USDtoPLNRate: data.rates[0].mid }))
      }

      fetchUsdRates()

      // console.log(fetchUsdRates())
      //! Rates-y z ktorych korzysta silnik znajduja sie w finish-suma.js
      const currencyToUSD = { PLN: 1, BTC: 19000.0, ETH: 1314, TRX: 0.06158 }

      let suma = 0
      let products = []
      cart.forEach(item => {
        suma += item.cena
      })
      suma += this.state.kosztDostawy
      cart.forEach(item => {
        products.push(item)
      })
      if (this.state.currency !== "PLN") {
        suma =
          suma / this.state.USDtoPLNRate / currencyToUSD[this.state.currency]
        console.log(suma)
      }

      fetch("https://zutigrafika.pl:8080/send", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.state.name,
          surname: this.state.surname,
          address: this.state.address,
          city: this.state.city,
          postalCode: this.state.postalCode,
          email: this.state.email,
          mobile: this.state.mobile,
          suma: suma,
          dostawa: this.state.kosztDostawy,
          products: products,
          currency: this.state.currency,
        }),
      }).then(response => {
        if (response.status === 200) {
          this.setState({
            success: true,
          })
        } else {
          console.error("Blad wysyłania!")
          this.setState({
            success: false,
          })
        }
      })
      let transactionId = Math.floor(Math.random() * 100000000 + 1)
      ReactGA.initialize("UA-153523838-1", {
        debug: false,
      })
      ReactGA.ga("require", "ecommerce")

      ReactGA.ga("ecommerce:addTransaction", {
        id: transactionId, // Transaction ID. Required.
        affiliation: "Zuti Grafika", // Affiliation or store name.
        revenue: suma, // Grand Total.
        shipping: this.state.kosztDostawy, // Shipping.
        tax: 0, // Tax.
      })

      products.forEach(product => {
        ReactGA.ga("ecommerce:addItem", {
          id: transactionId, // Transaction ID. Required.
          name: product.name, // Product name. Required.
          category: product.format,
          price: product.cena, // Unit price.
          quantity: "1", // Quantity.
        })
        // console.log(product)
      })
      ReactGA.ga("ecommerce:send")
    }
  }

  selectShippMethod(method) {
    this.setState({
      shippMethod: method,
    })
    if (method === "odbiorOsobisty") {
      this.setState({
        kosztDostawy: 0,
      })
    } else {
      this.setState({
        kosztDostawy: this.kosztKurier,
      })
    }
  }
  selectCurrency(method) {
    this.setState({
      currency: method,
    })
  }
  shippStatusClass() {
    if (this.state.success === true) {
      return "shippStatus success"
    } else if (this.state.success === false) {
      return "shippStatus success"
    } else {
      return "shippStatus notVisible"
    }
  }
  render() {
    return (
      <CartContext.Consumer>
        {cart => (
          <Layout>
            <SEO title="Finish payment" />

            <div className="shippDetails">
              <div className="shippInfo">
                <CartFinishStatus success={this.state.success} />
                <div className="form-basic">
                  <div className="form-title-row">
                    <h1>To już prawie koniec </h1>
                  </div>

                  <div className="form-row">
                    <label>
                      <span>Imię</span>
                      <input
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label>
                      <span>Nazwisko</span>
                      <input
                        type="text"
                        name="surname"
                        value={this.state.surname}
                        onChange={this.handleInputChange}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label>
                      <span>Adres</span>
                      <input
                        type="text"
                        name="address"
                        value={this.state.address}
                        onChange={this.handleInputChange}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label>
                      <span>Miasto</span>
                      <input
                        type="text"
                        name="city"
                        value={this.state.city}
                        onChange={this.handleInputChange}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label>
                      <span>Kod pocztowy</span>
                      <input
                        type="text"
                        name="postalCode"
                        value={this.state.postalCode}
                        onChange={this.handleInputChange}
                      />
                    </label>
                  </div>

                  <div className="form-row">
                    <label>
                      <span>Email</span>
                      <input
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label>
                      <span>Tel. kontaktowy</span>
                      <input
                        type="text"
                        name="mobile"
                        value={this.state.mobile}
                        onChange={this.handleInputChange}
                      />
                    </label>
                  </div>

                  <div className="form-row desktopOnly ">
                    <label className={this.state.checkBoxStyle}>
                      <input
                        name="regulamin"
                        type="checkbox"
                        checked={this.state.regulamin}
                        onChange={this.handleInputChange}
                      />
                      Akceptuje <Link to="/regulamin">regulamin</Link>
                    </label>
                  </div>
                  <div className="form-row desktopOnly">
                    <button
                      onClick={this.handleSubmit.bind(this, cart.cartItems)}
                      type="submit"
                    >
                      Złóż zamówienie
                    </button>
                  </div>
                </div>
              </div>

              <div className="shippMethod ">
                <h1 className="form-title-row">Wybierz sposób dostawy</h1>
                <table>
                  <tbody className={this.state.shippMethod}>
                    <tr
                      className="pointer"
                      onClick={this.selectShippMethod.bind(
                        this,
                        "odbiorOsobisty"
                      )}
                    >
                      <td>Odbiór osobisty</td>
                      <td>0 PLN</td>
                    </tr>
                    <tr
                      className="pointer"
                      onClick={this.selectShippMethod.bind(this, "kurier")}
                    >
                      <td>Kurier</td>
                      <td>{this.kosztKurier} PLN</td>
                    </tr>
                  </tbody>
                </table>
                <h1 className="form-title-row">Wybierz walute</h1>
                <table>
                  <tbody className={this.state.currency}>
                    <tr className="pointer">
                      <td
                        style={
                          this.state.currency === "PLN"
                            ? { fontWeight: "600" }
                            : {}
                        }
                        onClick={this.selectCurrency.bind(this, "PLN")}
                      >
                        PLN
                      </td>
                      <td
                        style={
                          this.state.currency === "BTC"
                            ? { fontWeight: "600" }
                            : {}
                        }
                        onClick={this.selectCurrency.bind(this, "BTC")}
                      >
                        BTC
                      </td>
                      <td
                        style={
                          this.state.currency === "ETH"
                            ? { fontWeight: "600" }
                            : {}
                        }
                        onClick={this.selectCurrency.bind(this, "ETH")}
                      >
                        ETH
                      </td>
                      <td
                        style={
                          this.state.currency === "TRX"
                            ? { fontWeight: "600" }
                            : {}
                        }
                        onClick={this.selectCurrency.bind(this, "TRX")}
                      >
                        TRX
                      </td>
                    </tr>
                  </tbody>
                </table>

                {this.state.currency != "PLN" ? (
                  <div>
                    <div className="cryptoDescription desktopOnly">
                      <p>Wybierając płatność w kryptowalucie mailem</p>
                      <p>otrzymasz sumę oraz numer portfela, na który należy</p>
                      <p>dokonać wpłaty. </p>
                    </div>
                    <div className="cryptoDescription mobileOnly">
                      <p>
                        Wybierając płatność w kryptowalucie mailem otrzymasz
                        sumę oraz numer portfela, na który należy dokonać
                        wpłaty.{" "}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <CartFinishSuma
                  cart={cart.cartItems}
                  shippMethod={this.state.kosztDostawy}
                  currency={this.state.currency}
                />
              </div>

              <label className="regulaminCheckBox mobileOnly">
                <input
                  name="regulamin"
                  type="checkbox"
                  checked={this.state.regulamin}
                  onChange={this.handleInputChange}
                />
                Akceptuje <Link to="/regulamin">regulamin</Link>
              </label>

              <button
                onClick={this.handleSubmit.bind(this, cart.cartItems)}
                type="submit"
                className="mobileOnly"
              >
                Złóż zamówienie
              </button>
            </div>
          </Layout>
        )}
      </CartContext.Consumer>
    )
  }
}

export default Cartfinish
